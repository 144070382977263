import { ScrollArea } from '@/src/components/ui/scroll-area';
import SideBarOptions from './sideBar_Options';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/src/redux/store';
import { IconCross, IconX } from '@tabler/icons-react';
import { setAdminNavbarState } from '@/src/redux/configuration/configuration';

type Props = {
    selectedId: string
}

export default function SideNav(props: Props) {

    const modalState = useSelector((state: RootState) => state.configuration.adminConfig.sideBarState)
    const dispatch = useDispatch()
    const state = useSelector((state:RootState) => state.configuration.adminConfig.sideBarState)


    return (
        <>
            <div style={{
                display:state==='open'?'block':'none'
            }} className=' sm:!hidden fixed inset-0  z-[999] bg-gray-700/40'>
                <div onClick={()=>dispatch(setAdminNavbarState('closed'))} className='p-2 bg-gray-50 rounded hover:bg-gray-200 cursor-pointer absolute right-2 top-2'><IconX/></div>
            </div>
            <ScrollArea style={{
                left: modalState === 'closed' ? -200 : 0
            }} className=' w-[200px] min-w-[200px] max-w-[200px] !fixed sm:!left-0 z-[1000] sm:!relative border-r sm:border-hidden border-black shadow-md sm:shadow-none  top-0 bottom-0  h-full overflow-auto flex flex-col pe-5 bg-adminPrimary-50  '>
                <div className='w-full pt-6 flex flex-col px-1 gap-2'>


                    <SideBarOptions title={"Inventory"} subTab={[
                        { link: "inventory/brand", name: "Brand", id: 'A8' },
                        { link: "inventory/warehouse_details", name: "Warehouse", id: 'A1' },
                        { link: "inventory/Product_category", name: "Product Category", id: 'A2' },
                        { link: "inventory/add_product", name: "Product", id: 'A3' },
                        { link: "inventory/add_product_sku", name: "Products SKU", id: 'A4' },
                        { link: "inventory/price_upload", name: "Price Upload", id: 'A10' },
                        // { link: "inventory/purchase", name: "Purchase", id: 'A5' },
                        { link: "inventory/stock", name: "stock", id: 'A6' },
                        { link: "inventory/ecommerceStock", name: "Ecommerce Stock", id: 'A7' },
                        { link: "inventory/stockUpload", name: "Stock Upload", id: 'A9' },
                    ]}
                        selectedId={props.selectedId}
                    />
                    <SideBarOptions title={"Order"} subTab={[
                        { link: "order/orders", name: "Orders", id: 'E1' },
                        { link: "order/serviceOrders", name: "Service Orders", id: 'E2' },
                    ]}
                        selectedId={props.selectedId}
                    />
                    <SideBarOptions title={"Promotions"} subTab={[
                        { link: "promotions/carousel", name: "Carousel", id: 'B1' },
                        { link: "promotions/rightcarousel", name: "Right Carousel", id: 'B2' },
                        { link: "promotions/blogs", name: "Blogs", id: 'B3' },
                    ]}
                        selectedId={props.selectedId} />
                    <SideBarOptions title={"Employee"} subTab={[
                        // { link: "employee/add_employee", name: "Add Employee", id: 'C1' },
                        { name: "Employees", link: "employee/list_employee", id: 'C2' },

                    ]}
                        selectedId={props.selectedId} />
                    <SideBarOptions title={"Others"} subTab={[
                        // { link: "employee/add_employee", name: "Add Employee", id: 'C1' },
                        { name: "Tax", link: "others/tax_config", id: 'O1' },
                        { name: "Shipping system", link: "others/shipping_system", id: 'O2' },
                    ]}
                        selectedId={props.selectedId} />
                    <SideBarOptions title={"Settings"} subTab={[
                        { link: "/settings/general", name: "General", id: 'D1' },
                        { name: "Account", link: "/settings/account", id: 'D2' }
                    ]}
                        selectedId={props.selectedId} />
                </div>
            </ScrollArea>
        </>
    );
}
