import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useSearchParams } from 'react-router-dom';
import Footer from '../../components/Footer/Footer';

interface BlogPost {
    P309_title: string;
    P309_subtitle: string;
    P309_content: string;
    P309_image: string;
    P309_post_date: string;
}

export default function Singleblog() {
    const { P309_title } = useParams<{ P309_title: string }>();
    const [searchParams] = useSearchParams();
    const blogId = searchParams.get('blogId');
    const [blog, setBlog] = useState<BlogPost | null>(null);

    useEffect(() => {
        const fetchBlog = async () => {
            if (!blogId) return;

            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_BACKEND_URL_CUSTOMER}/blogs/${blogId}`
                );
                if (response.data.success && response.data.data) {
                    setBlog(response.data.data);
                }
            } catch (error) {
                console.error('Error fetching blog:', error);
            }
        };

        fetchBlog();
    }, [blogId]);

    if (!blog) {
        return <div className="text-center py-10">Loading...</div>;
    }

    return (
        <>
            <div className="bg-gray-50 min-h-screen">
                <div className="max-w-4xl mx-auto py-10 px-5">
                    {/* Blog Image */}
                    <img
                        src={`${process.env.REACT_APP_BACKEND_URL}/${blog.P309_image}`}
                        alt={blog.P309_title}
                        className="w-full h-64 object-cover rounded-lg shadow-lg mb-6"
                    />

                    {/* Blog Title */}
                    <h1 className="text-4xl font-bold text-gray-800 mb-3">{blog.P309_title}</h1>

                    {/* Blog Subtitle */}
                    <h2 className="text-2xl font-medium text-gray-600 mb-4">{blog.P309_subtitle}</h2>

                    {/* Blog Date */}
                    <p className="text-gray-500 text-sm mb-6">
                        Published on{' '}
                        <span className="font-semibold">
                            {new Date(blog.P309_post_date).toLocaleDateString()}
                        </span>
                    </p>

                    {/* Blog Content */}
                    <div className="prose prose-lg max-w-none text-gray-700">
                        <p dangerouslySetInnerHTML={{ __html: blog.P309_content }}></p>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
}
