import React, { useEffect, useRef, useState } from 'react'
import useFormValidatonCustom, { validationObject } from '../../../../../../hooks/form_validation_hook'
import { useRESTapi } from '../../../../../../hooks/rest_API'
import { Brand, CustomFile, Product, ProductCategory, ProductImage, ProductItem, ProductItemKeys, Warehouse, WarehouseKeys, employee, pagination, role } from '../../../../../../types'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../../redux/store'
import { IconPlus, IconX } from '@tabler/icons-react'
import { v4 as uuidv4 } from 'uuid';
import { CustomSelectCustomOptionOption, SelectCustomLoadingSearching, loadOptionProps, loadOptionReturnProps, loadSelectedOptionProps, loadSelectedOptionReturnProps } from '../../../components/input_loading_searching'
import { AdminModal, AdminModalContent, AdminModalFooter, AdminModalHeader, AdminModalMiddle, AdminModalTitle } from '@/src/pages/admin/components/modal'
import { Input } from '@/src/components/ui/input'
import { Checkbox } from '@/src/components/ui/checkbox'
import { Switch } from '@/src/components/ui/switch'
import { Button } from '@/src/components/ui/button'
import { Label } from '@/src/components/ui/label'




type warehouseOverview = Warehouse & {
    images?: ProductImage[]
}

type imageIds = string[]



export function AddWarehouseModal(
    {
        show,
        closeModal,
        id,
        updateUi,
        mode,
        setShow
    }:
        {
            show: boolean,
            closeModal: () => void,
            id?: string,
            updateUi: () => void,
            mode: 'add' | 'edit',
            setShow: (show: boolean) => any
        }
) {


    const validationObject = {
        [WarehouseKeys.I01_Warehouse_name]: {
            message: '',
            validation: [
                (value) => {

                    if (!value) {
                        return 'required'
                    }
                    return true
                }
            ]
        },
        [WarehouseKeys.I01_Warehouse_email]: {
            message: '',
            validation: [
                (value) => {

                    if (!value) {
                        return 'required'
                    }
                    return true
                }
            ]
        },
        [WarehouseKeys.I01_Warehouse_address]: {
            message: '',
            validation: [
                (value) => {

                    if (!value) {
                        return 'required'
                    }
                    return true
                }
            ]
        },
        [WarehouseKeys.I01_Warehouse_incharge_employee_id]: {
            message: '',
            validation: [
                (value) => {

                    if (!value) {
                        return 'required'
                    }
                    return true
                }
            ]
        },
        [WarehouseKeys.I01_Warehouse_capacity]: {
            message: '',
            validation: [
                (value) => {

                    if (!value) {
                        return 'required'
                    }
                    return true
                }
            ]
        },

    } satisfies validationObject

    const api = useRESTapi();
    const employee = useSelector((state: RootState) => state.employee)
    const [formData, setFormData] = useState<warehouseOverview>({
        I01_Warehouse_address: '',
        I01_Warehouse_capacity: 0,
        I01_Warehouse_email: '',
        I01_Warehouse_incharge_employee_id: undefined,
        I01_Warehouse_is_active: 1,
        I01_Warehouse_name: ''
    })
    const [isLoading, setIsLoading] = useState(false)
    const { performAllValidation, performValidationOf, errorData, setErrorData } = useFormValidatonCustom<Warehouse>({
        validationObject: validationObject
    })
    const [loading, set_loading] = useState(false)
    const [images_url, set_image_url] = useState<Partial<Product>>({
        M11_ProductImages: [],
        images: []
    })




    function handleChangeInput(e: React.ChangeEvent<HTMLInputElement>) {
        console.log(e.target.name)
        let formdata: Warehouse|undefined = undefined;

        switch (e.target.name) {

            case WarehouseKeys.I01_Warehouse_is_active:
            case WarehouseKeys.I01_Warehouse_capacity:
            case WarehouseKeys.I01_Warehouse_incharge_employee_id:
            case WarehouseKeys.I01_Warehouse_name:
            case WarehouseKeys.I01_Warehouse_email:
            case WarehouseKeys.I01_Warehouse_address:
                formdata = {
                    ...formData,
                    [e.target.name]: e.target.value
                }
                break;
            default:
                
                break;
        }


        if(formdata){
            
            setFormData(formdata)
            performValidationOf(e.target.name, formdata)
        }

    }

    async function add_warehouse() {

        setIsLoading(true)
        // formData.M11_created_by = employee.M15_Employee_id!
        if (performAllValidation(formData) === true) {

            try {

                const formDataReal = new FormData();
                console.log(formData)
                Object.entries(formData).map(
                    data => {


                        formDataReal.append(data[0], String(data[1]))

                    }
                )

                // const anyFormData  = formData as any

                // for (const key in formData) {
                //     formDataReal.append(key, String(anyFormData[key]))
                // }

                const res = await api.post<{ success: boolean, data: employee, errors: any, message: string }>({
                    url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/warehouse`,
                    body: formDataReal,
                    mode: 'admin'
                })

                if (res.data.success === true) {

                    updateUi();
                    alert(res.data?.message || 'added product')
                    closeModal()
                } else {

                    alert(res.data?.message || 'something went wrong')
                    if (res.data.errors) {

                        const errs = res.data.errors;
                        const errData: any = {}

                        for (const key in errs) {
                            errData[key] = errs[key][0]
                        }

                        setErrorData(errData)

                    }
                }
            } catch (error) {
                console.log(error)
            }
        }
        setIsLoading(false)

    }
    async function edit_product() {

        setIsLoading(true)
        // formData.M11_created_by = employee.M15_Employee_id!
        if (performAllValidation(formData) === true) {

            try {

                const formDataReal = new FormData();
                formDataReal.append('_method', "PUT")

                Object.entries(formData).map(
                    data => {

                        formDataReal.append(data[0], String(data[1]))

                    }
                )



                const res = await api.post<{ success: boolean, data: Warehouse, errors: any, message: string }>({
                    url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/warehouse/${id}`,
                    body: formDataReal,
                    mode: 'admin'
                })

                if (res.data.success === true) {

                    updateUi();
                    alert(res.data?.message || 'updated product')
                    closeModal()
                    
                } else {
                    
                    alert(res.data?.message || 'something went wrong')
                    if (res.data.errors) {

                        const errs = res.data.errors;
                        const errData: any = {}

                        for (const key in errs) {
                            errData[key] = errs[key][0]
                        }

                        setErrorData(errData)

                    }
                }
            } catch (error) {
                console.log(error)
            }
        }
        setIsLoading(false)

    }


    const fetch_selected_Employee: (props: loadSelectedOptionProps) => Promise<loadSelectedOptionReturnProps<employee & CustomSelectCustomOptionOption>> = async (props: loadSelectedOptionProps) => {
        if (props.value) {
            const res = await api.get<{ success: boolean, data: employee, errors: any, message: string }>({
                url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/employees/${props.value}`,
                mode: 'admin'
            })

            if (res.data.success === false) {

                alert(res.data?.message || 'something went wrong')
                return {
                    options: null
                }
            } else {

                // alert(res.data.message)
                return {
                    options: {
                        ...res.data.data,
                        name: res.data.data.M15_Name,
                        value: res.data.data.M15_Employee_id,

                    } as (employee & CustomSelectCustomOptionOption)
                }
            }
        }
        return {
            options: null
        }
    }


    const load_warehouse = async () => {

        const res = await api.get<{ success: boolean, data: Warehouse, message?: string }>({
            url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/warehouse/${id}`,
            mode: 'admin'
        })
        console.log(loading)
        if (res.data.success === true) {
            // images_url.images = res.data.data.images ? res.data.data.images : []

            // res.data.data.M11_ProductImages = []
            setFormData(res.data.data)
            set_image_url(images_url)
        } else {
            throw res.data
        }


    }

    //load all suppliers
    const load_employees: (props: loadOptionProps) => Promise<loadOptionReturnProps<employee & CustomSelectCustomOptionOption>> = async ({
        pagination,
        search = ''
    }: {
        pagination: pagination,
        search?: string
    }) => {
        // set_product_search_query(e.target.value);
        const res = await api.get<{ success: boolean, data: employee[] } & pagination>({
            url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/employees`,
            mode: 'admin',
            params: {
                perPage: pagination.per_page,
                page: 1,
                searchTerm: search
            },
        })


        const pagination_return: pagination = {
            per_page: res.data.per_page,
            total: res.data.total
        }

        if (res.data.success === true) {
            // console.log(res.data)
            return {
                options: res.data.data.map(
                    (item, index) => {
                        return {
                            ...item,
                            value: String(item.M15_Employee_id),
                            name: item.M15_Name
                        } satisfies CustomSelectCustomOptionOption & employee
                    }
                ),
                pagination: pagination_return
            }

        } else {
            return {
                options: [],
                pagination: {
                    total: 30,
                    per_page: 30
                } as pagination
            }
        }
    }


    useEffect(
        () => {

            const initListSetup = () => {
                setFormData({
                    I01_Warehouse_address: '',
                    I01_Warehouse_capacity: 0,
                    I01_Warehouse_email: '',
                    I01_Warehouse_incharge_employee_id: undefined,
                    I01_Warehouse_is_active: 1,
                    I01_Warehouse_name: ''
                })
                setErrorData({})
                set_loading(false)
                console.log('cleared all')

            }
            const initEditSetup = () => {
                set_loading(true)

                Promise.all(
                    [load_warehouse()]
                ).then(
                    () => {
                        set_loading(false)

                    }
                ).catch(
                    (err) => {
                        set_loading(false)

                    }
                )

            }

            if (mode === 'add' && show === true) {
                setErrorData({})
                initListSetup()
            } else if (mode === 'edit' && show === true) {
                initEditSetup()
                // initListSetup()

            } else {
                set_loading(true)
                set_image_url({
                    M11_ProductImages: [],
                    images: []
                })
            }


        }, [show]
    )



    return (
        <AdminModal show={show} setShow={setShow} >

            <AdminModalContent>
                <>
                    <AdminModalHeader>
                        <AdminModalTitle>Add Warehouse</AdminModalTitle>
                    </AdminModalHeader>
                    <AdminModalMiddle>
                        <Label>Warehouse Name</Label>
                        <Input disabled={isLoading || loading} value={formData.I01_Warehouse_name} onChange={handleChangeInput} type="text" name={WarehouseKeys.I01_Warehouse_name} placeholder='Name' />
                        <div className=' text-red-500'>{errorData.I01_Warehouse_name ? errorData.I01_Warehouse_name : null}</div>
                        <div className=' h-2'></div>
                        <Input disabled={isLoading || loading} value={formData.I01_Warehouse_email} onChange={handleChangeInput} type="text" name={WarehouseKeys.I01_Warehouse_email} placeholder='email' />
                        <div className=' text-red-500'>{errorData.I01_Warehouse_email ? errorData.I01_Warehouse_email : null}</div>
                        <div>Warehouse Capacity</div>
                        <Input disabled={isLoading || loading} value={formData.I01_Warehouse_capacity} onChange={handleChangeInput} type="number" name={WarehouseKeys.I01_Warehouse_capacity} placeholder='Description' />
                        <div className=' text-red-500'>{errorData.I01_Warehouse_capacity ? errorData.I01_Warehouse_capacity : null}</div>
                        <div className=' h-2'></div>
                        <SelectCustomLoadingSearching<employee & CustomSelectCustomOptionOption>
                            fetchSelectOption={fetch_selected_Employee}
                            value={formData.I01_Warehouse_incharge_employee_id}
                            loadOption={load_employees}
                            name={WarehouseKeys.I01_Warehouse_incharge_employee_id}
                            placeholder='Warehouse Incharge'
                            onselect={handleChangeInput}
                            title='Warehouse Incharge'
                            disable={isLoading || loading}
                        />
                        <div className=' text-red-500'>{errorData.I01_Warehouse_incharge_employee_id ? errorData.I01_Warehouse_incharge_employee_id : null}</div>

                        <div className=' h-2'></div>
                        <div>Address</div>
                        <Input disabled={isLoading || loading} value={formData.I01_Warehouse_address} onChange={handleChangeInput} type="text" name={WarehouseKeys.I01_Warehouse_address} placeholder='Address' />
                        <div className=' text-red-500'>{errorData.I01_Warehouse_address ? errorData.I01_Warehouse_address : null}</div>

                        <div className=' h-2'></div>

                        <div className=' flex gap-1'>
                            <Switch disabled={isLoading || loading} checked={formData.I01_Warehouse_is_active === 1 ? true : false} name={WarehouseKeys.I01_Warehouse_is_active} onCheckedChange={
                                (checked) => handleChangeInput({
                                    target: {
                                        name: WarehouseKeys.I01_Warehouse_is_active,
                                        value: checked ? 1 : '0',
                                        checked: checked
                                    }
                                } as React.ChangeEvent<HTMLInputElement>)
                            } />
                            <div>is active</div>
                        </div>
                    </AdminModalMiddle>

                    <AdminModalFooter>
                        <div className=' flex justify-end'>
                            {
                                mode === 'add' ?
                                    <Button disabled={isLoading || loading} onClick={add_warehouse} >{isLoading ? 'adding...' : 'Add'}</Button>
                                    :
                                    <Button disabled={isLoading || loading} onClick={edit_product}  >{isLoading ? 'editing...' : 'edit'}</Button>}

                        </div>
                    </AdminModalFooter>
                </>
            </AdminModalContent>

        </AdminModal>
    )
}
