import React from 'react';
import { Link } from 'react-router-dom';

export default function Blogs() {
  return (
    <div className="bg-gray-100  p-6 relative my-5">
      {/* Page Header */}
      <header className="text-center py-10">
        <h1 className="text-4xl font-bold text-gray-800">ChefSouq Blog</h1>
        <p className="mt-4 text-gray-600">Your guide to the best kitchen equipment, tools, and usage tips.</p>
      </header>

      {/* Featured Post */}
      <section className="my-5 max-w-4xl mx-auto">
        <div className="relative rounded-lg overflow-hidden shadow-lg">
          <img
            src="https://as2.ftcdn.net/v2/jpg/05/99/71/63/1000_F_599716321_P6tpoKCBYkRxV1HEicNEHgdPCH9sA5dw.jpg" // replace with featured post image URL
            alt="Featured Blog Post"
            className="w-full h-72 object-cover"
          />
          <div className="absolute inset-0 bg-black bg-opacity-40 flex flex-col justify-end p-6">
            <h2 className="text-3xl font-semibold text-white">Featured: Choosing the Right Blender for Every Kitchen</h2>
            <p className="text-gray-200 mt-2">Explore the differences between blenders and find the perfect fit for your culinary needs.</p>
            <Link to={'/Blogs'} className='self-center'>
              <button className="mt-4 mx-10 bg-gray-800  text-white py-2 px-4 rounded hover:bg-gray-900">Read More</button>
            </Link>
          </div>
        </div>
      </section>

      {/* Recent Posts */}


      {/* Newsletter Signup
      <section className="my-16 text-center bg-yellow-50 py-10">
        <h4 className="text-2xl font-semibold text-gray-800">Subscribe to Our Newsletter</h4>
        <p className="text-gray-600 mt-2">Stay updated on the latest kitchen tools, tips, and equipment reviews.</p>
        <div className="mt-6 flex justify-center">
          <input
            type="email"
            placeholder="Your email address"
            className="border rounded-l px-4 py-2 w-64 focus:outline-none"
          />
          <button className="bg-yellow-500 text-white px-4 py-2 rounded-r hover:bg-yellow-600">Subscribe</button>
        </div>
      </section> */}
    </div>
  );
}
