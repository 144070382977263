import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Route, Routes } from 'react-router-dom'
import { initcartRedux } from 'src/redux/cart/cart_slice'
import ProductsPage from './Pages/AllProducts/ProductsPage'
import { CheckoutFromCart } from './Pages/CheckoutPageMultiple/checkout'
import Checkout from './Pages/Chekout/checkout'
import PagenotFound from './Pages/PagenotFound'
import { CartPage } from './Pages/cart/page'
import {CategoryProductsPage} from './Pages/categoryPage/catogery_productPage'
import Home from './Pages/homePage/home'
import Order from './Pages/orderPage/order'
import { ServicePage as ServiceDetailsPage } from './Pages/productPage copy/servicePage'
import { ProductPage } from './Pages/productPage/productPage'
import { ServiceAndSpareParts } from './Pages/serviceAndSpareParts/catogery_productPage'
import { ServicePage } from './Pages/servicePage/service'
import { SingleService } from './Pages/singleServicePage/singleServicePage'
import Singleorder from './Pages/singleorder/Singleorder'
import NavBar from './components/navBar/navBar'
import { CommonOrderPage } from './Pages/commonOrderPage/order'
import { ReturnRefundPolicy } from './Pages/refundAndRefund/page'
import { PrivacyPolicy } from './Pages/privacyPolicy/privacyPolicy'
import { AboutPage } from './Pages/aboutUsPage/aboutUsPage'
import { ShippingPolicy } from './Pages/ShippingPolicy/shippingPolicy'
import Blogpage from './Pages/BlogPage/Blogpage'
import Singleblog from './Pages/BlogPage/Singleblog'


export function CustomerRoutes() {

  const dispatch = useDispatch()

  const initRedux = ()=>{
    dispatch(initcartRedux({
      mode:'notLoggedIn'
    }))
  }

  useEffect(
    ()=>{
      // initialize redux
      initRedux()
    },[]
  )

  return (
    <div className=' w-full z-0 absolute '>
      <NavBar />
      {/* <CategoryTab/> */}
      <Routes>
        <Route index element={<Home key={'home-page'} />}></Route> <Route path='/home-page' element={<Home key={'home-page'} />}></Route>
        <Route path='/:name/product' element={<ProductPage />}></Route>
        <Route path='/:name/service-page' element={<ServiceDetailsPage />}></Route>
        <Route path='/all-products/*' element={<ProductsPage />}></Route>
        <Route path='/cart/*' element={<CartPage />}></Route>
        <Route path='/orderPage' element={<Order/>} />
        <Route path='/common-order-page' element={<CommonOrderPage/>} />
        <Route path='/return-refund-policy' element={<ReturnRefundPolicy/>} />
        <Route path='/PrivacyPolicy' element={<PrivacyPolicy/>} />
        <Route path='/shippingPolicy' element={<ShippingPolicy/>} />
        <Route path='/services' element={<ServicePage/>} />
        <Route path='/aboutUs' element={<AboutPage/>} />
        <Route path='/service-and-spareparts/*' element={<ServiceAndSpareParts/>} />
        <Route path='/singleorder/:orderId' element={<Singleorder/>} />
        <Route path='/singleServiceDetails/:orderId' element={<SingleService/>} />
        <Route path='/checkoutProduct/:productId/*' element={<Checkout/>} />
        <Route path='/checkout-from-cart/*' element={<CheckoutFromCart/>} />
        <Route path='/:name/categery-page' element={<CategoryProductsPage />} />
        <Route path='/Blogs' element={<Blogpage/>} />
        <Route path="/:P309_title/singleblog/" element={<Singleblog/>} />
        {/* <Route path='/address' element={<UserAddress/>} /> */}
        <Route path='/*' element={<PagenotFound />}></Route>
      </Routes>
    </div>
  )
}
