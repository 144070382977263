import { useEffect, useState } from 'react'
import { TablePagination } from '../../../../../components/table'
import { useRESTapi } from '../../../../../hooks/rest_API'
import { Carousel, ProductCategory, CarouselKeys, pagination, CarouselSmall, } from '../../../../../types'
import { AddRightCarouselModal } from './component/addmodal'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/src/components/ui/table'
import { ButtonIcon } from '@/src/components/buttons/buttonIcon'
import { Pencil, Trash } from 'lucide-react'
import { Button } from '@/src/components/ui/button'
import { CarouselTypeId } from '@/src/types/databaseFeeds'
import { useAlertModal } from '@/src/hooks/alert_modal'
import { SkeletonTableAdmin } from '../../../components/skeltonTable'
import { DeleteModal } from './component/deleteModal'
import { format } from 'date-fns'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { filterObjectTypeKeys } from '../../e-commerce/add_product_sku/page'
interface BlogsType {
  P309_blogs_id: number;
  P309_title: string;
  P309_subtitle: string;
  P309_content: string;
  P309_post_date: string;
  P309_M15_employee_id: number;
  P309_image: string;
  employee: {
    M15_name: string;
    M15_email: string;
  };
  page_number: number;
  delete: any,
  edit: any
}

type Props = {
  setSelectedId: (id: string) => any
}

export function BlogsCrud(props: Props) {

  const [products, set_products] = useState<BlogsType[]>([])
  const [mode, set_mode] = useState<'edit' | 'add'>('add')
  const api = useRESTapi()
  const [update, setUpdate] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [selectedEmployeeId, set_selected_product] = useState<string>()
  const [page_selected, set_page_selected] = useState<number>(1)
  const [products_total, set_products_total] = useState<number>(1)
  const [per_page, set_per_page] = useState<number>(30)
  const [isProductLoading, setisProductLoading] = useState(false)
  const alertModal = useAlertModal()
  const [showDeleteModal, setshowDeleteModal] = useState(false)
  const [isDeleteoading, setIsDeleteLoading] = useState(false)
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const header: {
    label: CarouselKeys | string,
    name: string
  }[] = [
      {
        label: 'page_number',
        name: "No"
      },
      {
        label: 'P309_title',
        name: "title"
      },
      {
        label: 'P309_post_date',
        name: "date"
      },
      {
        label: 'edit',
        name: ''
      },
      {
        label: 'delete',
        name: ''
      },
    ]

  const updateUi = () => {
    setUpdate(!update)
  }

  async function delete_product(id: number | string) {
    setIsDeleteLoading(true)

    try {

      const res = await api.Delete<{ success: boolean, data: BlogsType, message: string }>({
        url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/blog/${id}`,
        mode: 'admin',

      })
      updateUi();
      if (res.data.success === true) {
        alert(res.data.message)
        setIsDeleteLoading(false)
        setshowDeleteModal(false)
      } else {
        alert(res.data.message || 'something went wrong...')
      }


    } catch (error) {
      alert('Server error')
    }
    setIsDeleteLoading(false)

  }

  function clickEditButton(id: string) {
    set_selected_product(id)
    setShowModal(true)
    set_mode('edit')
  }
  function open_add_modal() {
    set_selected_product(undefined)
    setShowModal(true)
    set_mode('add')
  }

  useEffect(
    () => {
      props.setSelectedId('B3')
      const initData = async () => {

        setisProductLoading(true)

        const res = await api.get<{ success: boolean, message: string, data: { data: BlogsType[] } & pagination }>({
          url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/blogs`,
          params: {
            perPage: per_page,
            page: page_selected
          },
          mode: 'admin',

        })

        try {
          if (res.data.success === true) {
            console.log(res.data)
            setisProductLoading(false)
            set_products(res.data.data.data.map(
              (item, index) => {
                item.page_number = ((page_selected - 1) * per_page) + (index + 1)
                item.P309_post_date = format(new Date(item.P309_post_date), 'yyyy-MM-dd')
                item.delete = <ButtonIcon icon={<Trash className=' w-4 h-4' />} varient={'destructive'} onClick={() => { set_selected_product(String(item.P309_blogs_id!)); setshowDeleteModal(true) }} />
                item.edit = <ButtonIcon icon={<Pencil className=' w-4 h-4' />} onClick={() => clickEditButton(item.P309_blogs_id!.toString())} />
                return item;
              }
            ))
            set_products_total(res.data.data.total)
          } else {
            alertModal.open_alert_modal({
              content: res.data.message,
              footer: <Button onClick={alertModal.close_alert_modal}>Ok</Button>,
              title: 'Error'
            })
          }
        } catch (error) {
          console.log(error)
          alertModal.open_alert_modal({
            content: 'Server Error',
            footer: <Button onClick={alertModal.close_alert_modal}>Ok</Button>,
            title: 'Error'
          })
        }

      }

      initData()

    }, [update]
  )

  return (
    <div className='  grow flex flex-col items-center  overflow-hidden py-5 gap-2 px-1 sm:px-5 '>
      <AddRightCarouselModal
        id={selectedEmployeeId}
        show={showModal}
        closeModal={() => setShowModal(false)}
        updateUi={updateUi}
        mode={mode}
        setShow={setShowModal}
      />
      <DeleteModal
        show={showDeleteModal}
        setShow={setshowDeleteModal}
        onDelete={async () => {
          await delete_product(selectedEmployeeId!);
        }}
        isDeleteing={isDeleteoading}
      />
      <div className=' p-2 text-2xl font-bold'>Blogs list</div>
      <div className=' w-full flex justify-end' >
        <Button onClick={open_add_modal} className=' '>Add</Button>
      </div>
      <div className='grow overflow-auto  w-full border rounded-md'>
        {
          isProductLoading
            ?
            <SkeletonTableAdmin />
            :
            <Table className='styled-table w-full'>
              <TableHeader >
                <TableRow>
                  {
                    header.map(
                      (header, index) => <TableHead key={index}>{header.name}</TableHead>
                    )
                  }
                </TableRow>
              </TableHeader>
              <TableBody>
                {
                  products.length === 0
                    ?
                    'No data'
                    :
                    products.map(
                      (row: any, index) => (
                        <TableRow key={index}>
                          {
                            header.map(
                              (header, index) => <TableCell key={index}>{row[header.label]}</TableCell>
                            )
                          }
                        </TableRow>
                      )
                    )

                }
              </TableBody>
            </Table>
        }
      </div>
      <div>
        <TablePagination totalRows={products_total} rowsPerPage={per_page} onClickIndex={(e) => {

          set_page_selected(e);
          const newSearchParams = new URLSearchParams(searchParams);
          newSearchParams.set(filterObjectTypeKeys.pageNo, e ? String(e) : '');
          navigate(`?${newSearchParams.toString()}`, { replace: true });
          updateUi()
        }} selectedPage={page_selected} />
      </div>
    </div>
  )
}
