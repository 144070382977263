import React, { useEffect, useState } from 'react'
import { TablePagination } from '../../../../../components/table'
import { useRESTapi } from '../../../../../hooks/rest_API'
import { Product, ProductItem, ProductKeys, StockItem, StockKeys, Store, pagination, Warehouse, WarehouseKeys, Rack, BinItem } from '../../../../../types'
import { AddStockModal } from './component/addmodal'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/src/components/ui/table'
import { Button } from '@/src/components/ui/button'
import { ButtonIcon } from '@/src/components/buttons/buttonIcon'
import { Pencil, Trash } from 'lucide-react'
import { SkeletonTableAdmin } from '../../../components/skeltonTable'
import { DeleteAllModal, DeleteModal } from './component/deleteModal'
import { FilterComponent } from './component/filterComponent'
import { IconCheck, IconCheckbox, IconSquare, IconSquareCheckFilled } from '@tabler/icons-react'

type Props = {
  setSelectedId: (id: string) => any
}



export type filterObjectType = {
  ProductItemId?: string
}

export enum filterObjectTypeKeys {
  ProductItemId = 'ProductItemId',
  pageNo = 'pageNo'
}

export type StockResponse = StockItem & {
  product_item: Partial<ProductItem>,
  store: {
    I02_Store_id: string,
    I02_Store_name: string,
    I02_I01_Warehouse_Id: string,
    I02_Is_Active: string,
    created_at: string,
    updated_at: string,
    warehouseName: string,
    warehouseId: number,
    rackName: string,
    rackId: number,
    binName: string,
    binId: number
  },
}

type StockOveview = {
  stockId: number,
  checkbox: React.ReactNode
  page_number: number,
  warehouseName: string,
  storeName: string,
  rackName: string,
  binName: string,
  quantity: string | number,
  delete: any,
  edit: any,
  // imagePath:string,
  sku: any

}

export function StockCRUD(props: Props) {

  const [searchParams, setSearchParams] = useSearchParams();
  const [products, set_products] = useState<StockOveview[]>([])
  const [mode, set_mode] = useState<'edit' | 'add'>('add')
  const api = useRESTapi()
  const [showDeleteModal, setshowDeleteModal] = useState(false)
  const [showDeleteAllModal, setshowDeleteAllModal] = useState(false)
  const [update, setUpdate] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [selectedEmployeeId, set_selected_product] = useState<string>()
  const [page_selected, set_page_selected] = useState<number>(searchParams.get(filterObjectTypeKeys.pageNo) ? Number(searchParams.get(filterObjectTypeKeys.pageNo)) : 1)
  const [products_total, set_products_total] = useState<number>(1)
  const [per_page, set_per_page] = useState<number>(30)
  const navigate = useNavigate();
  const [showFilterPopover, setShowFilterPopover] = useState(false)

  const [isOrderLoading, setisOrderLoading] = useState(false)
  const [isDeleteoading, setIsDeleteLoading] = useState(false)
  const [selectedIds, setSelectedIs] = useState<number[]>([])
  const [filterObject, setfilterObject] = useState<filterObjectType>({
    ProductItemId: searchParams.get(filterObjectTypeKeys.ProductItemId) ? searchParams.get(filterObjectTypeKeys.ProductItemId)! : '',
  })

  const header: {
    label: StockKeys | string,
    name: React.ReactNode
  }[] = [
      {
        label: 'checkbox',
        name: selectedIds.length === products.length&&selectedIds.length>0?<IconSquareCheckFilled className='cursor-pointer' onClick={() => { setSelectedIs([]) }} />:<IconSquare className='cursor-pointer' onClick={() => { setSelectedIs(products.map(item => item.stockId)) }} />
      },
      {
        label: 'page_number',
        name: "No"
      },
      {
        label: 'warehouseName',
        name: "WareHouse Name"
      },
      {
        label: 'storeName',
        name: "store"
      },
      {
        label: 'rackName',
        name: "rack"
      },
      {
        label: 'binName',
        name: "bin"
      },
      {
        label: 'sku',
        name: "SKU"
      },
      {
        label: 'quantity',
        name: 'quantity'
      },
      {
        label: 'edit',
        name: ''
      },
      {
        label: 'delete',
        name: ''
      },
    ]

  const updateUi = () => {
    setUpdate(!update)
  }


  async function delete_product(id: number | string) {

    setIsDeleteLoading(true)
    try {

      const res = await api.Delete<{ success: boolean, data: StockOveview[], message: string }>({
        url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/actual_stock/${id}`,
        mode: 'admin',

      })
      updateUi();
      if (res.data.success) {
        setshowDeleteModal(false)
        alert(res.data.message)
      } else {
        alert(res.data.message)
      }


    } catch (error) {
      console.log(error)
    }
    setIsDeleteLoading(false)

  }

  function clickEditButton(id: string) {
    set_selected_product(id)
    setShowModal(true)
    set_mode('edit')
  }
  function open_add_modal() {
    set_selected_product(undefined)
    setShowModal(true)
    set_mode('add')
  }

  const onclickCheckBox = (id: number) => {
    return () => {
      if (selectedIds.includes(id)) {
        setSelectedIs(selectedIds.filter(item => item !== id))
      } else {
        setSelectedIs([...selectedIds, id])
      }
    }
  }

  useEffect(
    () => {

      props.setSelectedId('A6')

      const initData = async () => {
        setisOrderLoading(true)
        const res = await api.get<{ success: boolean, data: { data: StockResponse[] } & pagination }>({
          url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/actual_stock`,
          params: {
            perPage: per_page,
            page: page_selected,
            ProductItemId: searchParams.get(filterObjectTypeKeys.ProductItemId),
          },
          mode: 'admin',

        })

        if (res.data.success === true) {
          // console.log(res.data)
          set_products(res.data.data.data.map(
            (item, index) => {

              const stockOne: StockOveview = {
                stockId: Number(item.I04_Stock_id),
                checkbox: <IconSquare onClick={onclickCheckBox(Number(item.I04_Stock_id))} className='cursor-pointer' />,
                page_number: ((page_selected - 1) * per_page) + (index + 1),
                quantity: item.I04_Quantity!,
                storeName: String(item.store.I02_Store_name),
                binName: String(item.store.binName),
                rackName: String(item.store.rackName),
                warehouseName: item.store.warehouseName!,
                delete: <ButtonIcon icon={<Trash className="h-4 w-4" />} onClick={() => { set_selected_product(String(item.I04_Stock_id!)); setshowDeleteModal(true) }}></ButtonIcon>,
                edit: <ButtonIcon icon={<Pencil className="h-4 w-4" />} onClick={() => clickEditButton(String(item.I04_Stock_id))}></ButtonIcon>,
                sku: (
                  <div className=' flex flex-col'>
                    <img className=' h-5 w-5' src={`${process.env.REACT_APP_BACKEND_URL}/${item?.product_item?.M08_ProductImage}`} />
                    <div>{item?.product_item?.M08_SKU}</div>
                  </div>
                )
              }
              return stockOne;
            }
          ))
          set_products_total(res.data.data.total)
          setisOrderLoading(false)
        }

      }

      initData()

    }, [update]
  )

  const handleDownload = async () => {
    try {
      // Make a GET request to the backend to fetch the Excel file
      const response = await api.post({
        url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/actual_stock`,
        headers: {
          'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',  // Expecting an Excel file
        },
        mode: 'admin',
        responseType: 'blob',
        params: {
          ProductItemId: searchParams.get(filterObjectTypeKeys.ProductItemId),
        }
      });

      if (response.status === 200) {
        // Convert the response data to a Blob object
        const blob = response.data as Blob;

        // Create a link element to trigger the download
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);  // Create a URL for the Blob
        link.download = 'actual_stock.xlsx';  // Specify the file name for the download

        // Trigger the download by clicking the link programmatically
        link.click();

        // Clean up by revoking the Object URL
        URL.revokeObjectURL(link.href);
      } else {
        // Handle error response if the download fails
        console.error('Failed to download the file');
      }
    } catch (error) {
      // Catch any errors during the Axios request
      console.error('Error during download:', error);
    }
  };

  if (products.length) {
    products.forEach((product) => {
      if (selectedIds.includes(product.stockId)) {
        product.checkbox = <IconSquareCheckFilled onClick={onclickCheckBox(product.stockId)} className='cursor-pointer' />
      } else {
        product.checkbox = <IconSquare onClick={onclickCheckBox(product.stockId)} className='cursor-pointer' />
      }
    })
  }

  return (
    <div className='  grow flex flex-col items-center  overflow-hidden py-5 gap-2 px-1 sm:px-5 '>
      <AddStockModal
        id={selectedEmployeeId}
        show={showModal}
        closeModal={() => setShowModal(false)}
        updateUi={updateUi}
        mode={mode}
        setShow={setShowModal}

      />
      <DeleteModal
        show={showDeleteModal}
        setShow={setshowDeleteModal}
        onDelete={async () => {
          await delete_product(selectedEmployeeId!);
        }}
        isDeleteing={isDeleteoading}
      />
      <DeleteAllModal
        show={showDeleteAllModal}
        setShow={setshowDeleteAllModal}
        onDelete={async () => {
          setIsDeleteLoading(true)
          await Promise.all(
            selectedIds.map(async (id) => {
              const res = await api.Delete<{ success: boolean, data: StockOveview[], message: string }>({
                url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/actual_stock/${id}`,
                mode: 'admin',
        
              })
              setSelectedIs(selectedIds=>selectedIds.filter(item=>item!==id))
          }))
          setIsDeleteLoading(false)
          setshowDeleteAllModal(false)
          updateUi();
        }}
        isDeleteing={isDeleteoading}
      />
      <div className=' p-2 text-2xl font-bold'>Stock list</div>
      <div className=' flex flex-col items-end w-full gap-1'>
        <div className=' w-full flex justify-end gap-2' >
          <FilterComponent
            filterObj={filterObject}
            setFilterObj={setfilterObject}
            updateUi={updateUi}
            show={showFilterPopover}
            setshow={setShowFilterPopover}
          />
          <Button onClick={open_add_modal} className=' '>Add Stock</Button>
        </div>
        <div className=' flex gap-2 items-center'>
          <p className=' text-blue-300 cursor-pointer hover:underline' onClick={handleDownload} >export</p>
          {
            selectedIds.length > 0?
            <Button onClick={() => setshowDeleteAllModal(true)}>Delete all</Button>
            :null}
        </div>
      </div>
      <div className='grow overflow-auto  w-full border rounded-md'>
        {
          isOrderLoading
            ?
            <SkeletonTableAdmin />
            :
            <Table className='styled-table w-full'>
              <TableHeader >
                <TableRow>
                  {
                    header.map(
                      (header, index) => <TableHead key={index}>{header.name}</TableHead>
                    )
                  }
                </TableRow>
              </TableHeader>
              <TableBody>
                {
                  products.length === 0
                    ?
                    'No data'
                    :
                    products.map(
                      (row: any, index) => (
                        <TableRow key={index}>
                          {
                            header.map(
                              (header, index) => <TableCell key={index}>{row[header.label]}</TableCell>
                            )
                          }
                        </TableRow>
                      )
                    )
                }
              </TableBody>
            </Table>
        }
      </div>
      <div>
        <TablePagination totalRows={products_total} rowsPerPage={per_page} onClickIndex={(e) => {
          set_page_selected(e);
          const newSearchParams = new URLSearchParams(searchParams);
          newSearchParams.set(filterObjectTypeKeys.pageNo, e ? String(e) : '');
          navigate(`?${newSearchParams.toString()}`, { replace: true });
          updateUi()
        }} selectedPage={page_selected} />
      </div>
    </div>
  )
}
