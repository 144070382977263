import React from 'react'
import { ChevronDown } from 'lucide-react';




import { Button } from "../../../../components/ui/button"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../../../components/ui/dropdown-menu"
import { useNavigate } from 'react-router-dom';


interface Category {
  name: string;
  category_id: string;
  image: string;
  items?: {
    category_id: string;
    name: string,
    image: string
  }[];
}

interface Props {
  category: Category;
}

export default function CategoryDropDown({ category }: Props) {

  const { name, items } = category;
  const navigate = useNavigate();
  return (
    <div>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          {
            items !== undefined && items.length > 0
              ?
              <button className=' whitespace-nowrap rounded-full bg-[#bdbdbd4a] text-white flex py-4 px-3 justify-center items-center font-semibold text-xs h-[25px]  hover:border-white border border-transparent '>{name}{<ChevronDown size={20} className='opacity-25' />} </button>
              :
              <button onClick={() => {
                const name = category.name.replace(/ /g, '-').toLowerCase()
                navigate(`${name}/categery-page?categoryId=${category.category_id}`)
              }} className='py-2 whitespace-nowrap  hover:border-2 hover:bg-blue-gray-700 hover:m-1 m-1 border-2 border-transparent rounded-[2px]  text-white flex px-3 justify-center items-center font-semibold text-xs h-[25px]'>{name}</button>
          }
          {/* <IconUserCircle size={28}/> */}


        </DropdownMenuTrigger>
        {items !== undefined && items.length > 0 && <DropdownMenuContent className="w-56 z-[5000000]">
          {/* <DropdownMenuLabel>My Account</DropdownMenuLabel> */}
          {/* <DropdownMenuSeparator /> */}
          {items !== undefined && items.map((item, index) => (
            <DropdownMenuItem key={index}>
              {/* <User className="mr-2 h-4 w-4" /> */}
              <span>{item.name}</span>
            </DropdownMenuItem>
          ))}
        </DropdownMenuContent>}
      </DropdownMenu>

    </div>
  )
}
