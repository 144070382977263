import { Button } from '@/src/components/ui/button'
import { Input } from '@/src/components/ui/input'
import { Switch } from '@/src/components/ui/switch'
import { AdminModal, AdminModalContent, AdminModalFooter, AdminModalHeader, AdminModalMiddle, AdminModalTitle } from '@/src/pages/admin/components/modal'
import { CarouselTypeId } from '@/src/types/databaseFeeds'
import { IconLoader, IconPlus } from '@tabler/icons-react'
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import useFormValidatonCustom, { validationObject } from '../../../../../../hooks/form_validation_hook'
import { useRESTapi } from '../../../../../../hooks/rest_API'
import { RootState } from '../../../../../../redux/store'
import { CarouselSmall, CarouselSmallEnum, CustomFile } from '../../../../../../types'
import { SelectShadcn } from '@/src/pages/customer/components/Select/selectShadcn'
import { Textarea } from '@/src/components/ui/textarea'
import { format } from 'date-fns'
import { useAlertModal } from '@/src/hooks/alert_modal'




type BlogsOverview = {
    P309_title: string,
    P309_subtitle: string,
    P309_content: string,
    P309_post_date: string,
    P309_M15_employee_id: number,
    P309_is_feature: 1 | 0,
    image: string | File,
    P309_image?: string
}
type imageIds = string[]



export function AddRightCarouselModal(
    {
        show,
        closeModal,
        id,
        updateUi,
        mode,
        setShow
    }:
        {
            show: boolean,
            closeModal: () => void,
            id?: string,
            updateUi: () => void,
            mode: 'add' | 'edit',
            setShow: (show: boolean) => any
        }
) {


    const validationObject = {
        P309_title: {
            message: '',
            validation: [
                (value) => {

                    if (!value) {
                        return 'required'
                    }
                    return true
                }
            ]
        },
        P309_subtitle: {
            message: '',
            validation: [
                (value) => {

                    if (!value) {
                        return 'required'
                    }
                    return true
                }
            ]
        },
        P309_content: {
            message: '',
            validation: [
                (value) => {

                    if (!value) {
                        return 'required'
                    }
                    return true
                }
            ]
        },
        P309_post_date: {
            message: '',
            validation: [
                (value) => {

                    if (!value) {
                        return 'required'
                    }
                    return true
                }
            ]
        },
        P309_M15_employee_id: {
            message: '',
            validation: [
                (value) => {

                    if (!value) {
                        return 'required'
                    }
                    if (isNaN(value)) {
                        return 'enter valid employee id'
                    }
                    return true
                }
            ]
        },
    } satisfies validationObject

    const api = useRESTapi();
    const employee = useSelector((state: RootState) => state.employee)
    const [formData, setFormData] = useState<BlogsOverview>({
        P309_content: '',
        P309_is_feature: 0,
        P309_M15_employee_id: employee.employeeData.M15_Employee_id ? Number(employee.employeeData.M15_Employee_id)! : NaN,
        P309_post_date: format(new Date(), 'yyyy-MM-dd'),
        P309_subtitle: '',
        P309_title: '',
        image: '',

    })

    const { performAllValidation, performValidationOf, errorData, setErrorData } = useFormValidatonCustom<BlogsOverview>({
        validationObject: validationObject
    })
    const [loading, set_loading] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isSpinLoading, setSpinLoading] = useState(false)
    const {
        close_alert_modal,
        open_alert_modal
    } = useAlertModal()


    const image_input_ref = useRef<HTMLInputElement>(null)
    const [largeImageImage, setlargeImageImage] = useState<{
        imageUrl: string,
        mode: 'local' | 'cloude'
    }>({
        imageUrl: '',
        mode: 'local'
    })
    const [add_progress, set_add_progress] = useState(0)


    console.log(formData)

    function handleChangeInput(e: React.ChangeEvent<HTMLInputElement>) {

        let formdata: BlogsOverview;

        switch (e.target.name) {
            case 'P309_title':
            case 'P309_subtitle':
            case 'P309_content':
            case 'P309_post_date':
            case 'P309_M15_employee_id':
            case 'P309_is_feature':
                if (image_input_ref.current) {
                    image_input_ref.current.value = ''
                }
                formdata = {
                    ...formData,
                    [e.target.name]: e.target.value,
                }
                break

            case 'image':

                if (e.target.files && e.target.files.length > 0) {

                    setSpinLoading(true)
                    const file = e.target.files[0];
                    const url = URL.createObjectURL(file)
                    setlargeImageImage({
                        imageUrl: url,
                        mode: 'local'
                    })


                    formdata = {
                        ...formData,
                        image: file
                    }

                    setSpinLoading(false)


                } else {
                    formdata = {
                        ...formData,
                    }
                }


                break

            default:
                formdata = {
                    ...formData,
                    [e.target.name]: e.target.value
                }
                break;
        }




        setFormData(formdata)
        performValidationOf(e.target.name, formdata)
        console.log(formdata)

    }

    async function add_carousel() {

        setIsLoading(true)
        // formData.M11_created_by = employee.M15_Employee_id!
        if (performAllValidation(formData) === true) {

            console.log(formData)
            if (!formData.image) {
                setIsLoading(false)


                open_alert_modal({
                    content: 'Please select an image',
                    footer: <Button variant={'outline'} onClick={close_alert_modal}>Close</Button>,
                    title: 'Error'
                })

                return
            }
            try {

                const formDataReal = new FormData();
                console.log(formData)
                Object.entries(formData).map(
                    data => {

                        switch (data[0]) {
                            case 'image':
                                const image = data[1] as File;
                                formDataReal.append(`${data[0]}`, image)
                                break;

                            case 'P309_is_feature':
                                formDataReal.append(data[0], String(data[1]))
                                break
                            case 'P309_title':
                            case 'P309_subtitle':
                            case 'P309_content':
                            case 'P309_post_date':
                            case 'P309_M15_employee_id':
                                formDataReal.append(data[0], data[1] ? String(data[1]) : '')
                                break;
                        }
                    }

                )



                const res = await api.post<{ success: boolean, data: BlogsOverview, errors: any, message: string }>({
                    url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/blog`,
                    body: formDataReal,
                    mode: 'admin',
                    onUploadProgress: (progressEvent) => {
                        const percentCompleted = Math.round((progressEvent.loaded * 100) / (progressEvent.total || 0))
                        set_add_progress(percentCompleted)
                    }
                })

                if (res.data.success === true) {
                    closeModal()
                    updateUi();
                    alert('added carousel')


                } else {
                    alert(res.data?.message || 'something went wrong')
                    if (res.data.errors) {

                        const errs = res.data.errors;
                        const errData: any = {}

                        for (const key in errs) {
                            errData[key] = errs[key][0]
                        }

                        setErrorData(errData)

                    }
                }
            } catch (error) {
                console.log(error)
            }
        }
        setIsLoading(false)

    }
    async function edit_product() {

        setIsLoading(true)
        // formData.M11_created_by = employee.M15_Employee_id!
        if (performAllValidation(formData) === true) {

            try {

                const formDataReal = new FormData();
                formDataReal.append('_method', "PUT")

                Object.entries(formData).map(
                    data => {

                        switch (data[0]) {
                            // case CarouselSmallEnum.C303_source_path:
                            //     const images = data[1] as CustomFile
                            //     formDataReal.append(`${data[0]}`, data[1] ? images.file:'')
                            //     break;
                            case 'image':
                                const image = data[1] as File;
                                formDataReal.append(`${data[0]}`, image)
                                break;
                            case 'P309_is_feature':
                                formDataReal.append(data[0], String(data[1]) )
                                break;
                            case 'P309_title':
                            case 'P309_subtitle':
                            case 'P309_content':
                            case 'P309_post_date':
                            case 'P309_M15_employee_id':
                                formDataReal.append(data[0], data[1] ? String(data[1]) : '')
                                break;
                        }
                    }

                )




                // const anyFormData  = formData as any

                // for (const key in formData) {
                //     formDataReal.append(key, String(anyFormData[key]))
                // }

                // console.log(images_url_deleted)

                const res = await api.put<{ success: boolean, data: BlogsOverview, errors: any, message: string }>({
                    url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/blog/${id}`,
                    body: formDataReal,
                    mode: 'admin',
                    onUploadProgress: (progressEvent) => {
                        const percentCompleted = Math.round((progressEvent.loaded * 100) / (progressEvent.total || 0))
                        set_add_progress(percentCompleted)
                    }
                })

                if (res.data.success === true) {

                    updateUi();
                    closeModal();
                    alert(res.data?.message || 'updated Carousel')

                } else {
                    alert(res.data?.message || 'something went wrong')
                    if (res.data.errors) {

                        const errs = res.data.errors;
                        const errData: any = {}

                        for (const key in errs) {
                            errData[key] = errs[key][0]
                        }

                        setErrorData(errData)

                    }

                }
            } catch (error) {
                console.log(error)
            }
        }
        setIsLoading(false)

    }

    const load_carousel = async () => {

        const res = await api.get<{
            success: boolean, data: BlogsOverview
        }>({
            url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/blogs/${id}`,
            mode: 'admin',

        })

        if (res.data.success === true) {
            if (res.data.data.P309_image) {
                setlargeImageImage({
                    imageUrl: `${res.data.data.P309_image}`,
                    mode: 'cloude'
                })

            }
            setFormData({
                ...res.data.data,
                P309_is_feature: res.data.data.P309_is_feature ? 1 : 0
            })
        } else {
            throw res.data
        }
    }


    const clear_datas = () => {
        setlargeImageImage({
            imageUrl: '',
            mode: 'local'
        })
        setlargeImageImage({
            imageUrl: '',
            mode: 'local'
        })
        setErrorData({})
        setFormData({
            P309_content: '',
            P309_is_feature: 0,
            P309_M15_employee_id: employee.employeeData.M15_Employee_id ? Number(employee.employeeData.M15_Employee_id)! : NaN,
            P309_post_date: format(new Date(), 'yyyy-MM-dd'),
            P309_subtitle: '',
            P309_title: '',
            image: ''
        })
    }



    useEffect(
        () => {
            clear_datas()
            const initListSetup = () => {
                set_loading(true)
                setFormData({
                    P309_content: '',
                    P309_is_feature: 0,
                    P309_M15_employee_id: employee.employeeData.M15_Employee_id ? Number(employee.employeeData.M15_Employee_id)! : NaN,
                    P309_post_date: format(new Date(), 'yyyy-MM-dd'),
                    P309_subtitle: '',
                    P309_title: '',
                    image: ''
                })

                Promise.all(
                    []
                ).then(
                    () => {
                        set_loading(false)
                    }
                ).catch(
                    (err) => {
                        set_loading(false)
                    }
                )

            }
            const initEditSetup = () => {
                set_loading(true)

                Promise.all(
                    [load_carousel()]
                ).then(
                    () => {
                        set_loading(false)

                    }
                ).catch(
                    (err) => {
                        set_loading(false)

                    }
                )

            }

            if (mode === 'add' && show === true) {
                setErrorData({})
                initListSetup()
            } else if (mode === 'edit' && show === true) {
                initEditSetup()
            } else {
                set_loading(true)

            }


        }, [show]
    )


    const delete_image_large = () => {
        // formData.C303_source_path = ''
        setFormData({ ...formData })
        setlargeImageImage({
            imageUrl: '',
            mode: 'local'
        })
    }


    const onclick_add_image_input_large = () => {
        image_input_ref.current?.click();
        image_input_ref.current!.value = '';

    }



    return (
        <AdminModal show={show} setShow={setShow}>
            <AdminModalContent disableCloseButton={isLoading}>

                <AdminModalHeader progressValue={add_progress}>
                    <AdminModalTitle >Add Blogs</AdminModalTitle>

                </AdminModalHeader>
                <AdminModalMiddle>
                    {
                        isSpinLoading
                        &&
                        <div className=' fixed inset-0 flex justify-center items-center z-[500] bg-gray-800/20'>
                            <div className=' animate-spin '><IconLoader /></div>
                        </div>
                    }
                    {
                        loading === true
                            ?
                            <div className=' bg-white p-3'>Loading ...</div>
                            :
                            < >
                                <div className=' h-3'></div>
                                <div className=''>Title</div>
                                <Input disabled={loading || isLoading} value={formData.P309_title} onChange={handleChangeInput} type="text" name={'P309_title'} placeholder='title' />
                                <div className=' text-red-500'>{errorData.P309_title ? errorData.P309_title : null}</div>
                                <div className=' h-3'></div>
                                <div className=''>Subtitle </div>
                                <Input disabled={loading || isLoading} value={formData.P309_subtitle} onChange={handleChangeInput} name={'P309_subtitle'} placeholder='subtitle' />
                                <div className=' text-red-500'>{errorData.P309_subtitle ? errorData.P309_subtitle : null}</div>
                                <>
                                    <div className=' h-3'></div>
                                    <div>Image </div>
                                    {largeImageImage.imageUrl ?
                                        <div className=' relative h-20 aspect-[1.87] group'>
                                            <img src={`${largeImageImage.mode === 'cloude' ? `${process.env.REACT_APP_BACKEND_URL}/${largeImageImage.imageUrl}` : largeImageImage.imageUrl}`} alt="" className=' h-full w-full' />
                                            <div className=' absolute inset-0 bg-black/50 z-10 opacity-0 group-hover:opacity-100 flex flex-col items-center justify-center '>
                                                <Button disabled={loading || isLoading} onClick={() => delete_image_large()} >delete</Button>
                                            </div>
                                        </div>
                                        : <div onClick={onclick_add_image_input_large} className=' h-20 aspect-[1.87] border-2 border-dotted flex items-center justify-center cursor-pointer' ><IconPlus /></div>}
                                    <Input disabled={loading || isLoading} className=' hidden' ref={image_input_ref} onChange={handleChangeInput} type="file" accept="image/*" name={'image'} placeholder='Image' />

                                </>
                                <div className=' h-3'></div>
                                <div>Content</div>
                                <Textarea disabled={loading || isLoading} value={formData.P309_content} onChange={handleChangeInput as any} name={'P309_content'} placeholder='Content' />
                                <div className=' text-red-500'>{errorData.P309_content ? errorData.P309_content : null}</div>
                                <div className=' h-3'></div>
                                <div className=' flex gap-1'>
                                    <Switch disabled={loading || isLoading} checked={formData.P309_is_feature == 1 ? true : false} onCheckedChange={checked => handleChangeInput({
                                        target: {
                                            value: checked ? '1' : '0',
                                            name: 'P309_is_feature'
                                        }
                                    } as React.ChangeEvent<HTMLInputElement>)} />
                                    <div>is featured</div>
                                </div>
                            </>
                    }
                </AdminModalMiddle>
                <AdminModalFooter>
                    {
                        mode === 'add' ?
                            <Button disabled={isLoading} onClick={add_carousel} >{isLoading ? 'adding...' : 'Add'}</Button>
                            :
                            <Button disabled={isLoading} onClick={edit_product}  >{isLoading ? 'editing...' : 'edit'}</Button>}

                </AdminModalFooter>
            </AdminModalContent>
        </AdminModal>
    )

}
