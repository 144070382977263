import React, { useState, useEffect, useRef } from 'react';
import CategoryDropDown from '../categoryDropDown/categoryDropDown';
import { ProductCategory } from '../../../../types';
import { useRESTapi } from '../../../../hooks/rest_API';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { useConfiguration } from 'src/hooks/configuration_hook';
import { useNavigate } from 'react-router-dom';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';

interface Category {
  name: string;
  category_id: string;
  image: string;
  items?: {
    category_id: string;
    name: string,
    image: string
  }[];
}

type newcategory = ProductCategory & {
  children: ProductCategory[]
}

const CategoryTab: React.FC = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(true);

  const [prevScrollPos, setPrevScrollPos] = useState<number>(0);
  const [visible, setVisible] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState(true)
  const api = useRESTapi()
  const navigate = useNavigate()
  const categoryTabConfigurations = useSelector((state: RootState) => state.configuration.categoryTab)
  const {
    show_category_tab,
    hide_category_tab,
    set_category_tab_theme
  } = useConfiguration()
  const [categories, set_categories] = useState<Category[]>([
  ])


  const handleScroll = () => {
    if (!containerRef.current) return;

    const { scrollLeft, scrollWidth, clientWidth } = containerRef.current;

    setShowLeftArrow(scrollLeft > 0);
    setShowRightArrow(scrollLeft < scrollWidth - clientWidth);
  };


  function convertToNestedArray(categories: ProductCategory[]): newcategory[] {
    const categoryMap = new Map<number | string, newcategory>();
    const result: newcategory[] = [];


    // Create a deep copy of categories
    const categoriesCopy: newcategory[] = JSON.parse(JSON.stringify(categories));

    // Create a map of categories with their IDs
    categoriesCopy.forEach(category => {
      category.children = []
      categoryMap.set(category.M04_ProductCategoryId!, category);
    });

    // Populate children of each category
    categoriesCopy.forEach(category => {
      const parentCategoryId = category.M04_ParentCategoryId;
      if (parentCategoryId && parentCategoryId !== null) {
        const parentCategory = categoryMap.get(parentCategoryId);
        if (parentCategory) {
          parentCategory.children.push(category);
        }
      } else {
        result.push(category); // If category has no parent, push it to the result array
      }
    });

    return result;
  }

  const loadCatogeries = async () => {
    setIsLoading(true)
    try {
      const res = await api.get<{ success: boolean, data: ProductCategory[], message: string }>({
        url: `${process.env.REACT_APP_BACKEND_URL_CUSTOMER!}/product_categories`,
        mode: 'customer',
      })

      if (res.data.success === true) {
        const tabs = convertToNestedArray(res.data.data)

        set_categories(
          tabs.map(
            item => ({
              category_id: String(item.M04_ProductCategoryId!),
              name: item.M04_ProductCategoryName,
              image: item.M04_ProductCategoryImage! as string,
              items: item.children.map(
                itemChildren => ({
                  category_id: String(itemChildren.M04_ProductCategoryId!),
                  name: itemChildren.M04_ProductCategoryName!,
                  image: itemChildren.M04_ProductCategoryImage! as string
                })
              )
            })
          )
        )
        setIsLoading(false)
      }
    } catch (error) {
      alert('something went wrong')
    }

  }

  useEffect(
    () => {
      handleScroll(); // Initial check
    }, [categories.length]
  )

  useEffect(
    () => {
      loadCatogeries()
      handleScroll(); // Initial check
      const ref = containerRef.current;
      if (ref) {
        ref.addEventListener('scroll', handleScroll);
        return () => ref.removeEventListener('scroll', handleScroll);
      }
    }, []
  )

  const scrollLeft = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({ left: -150, behavior: 'smooth' });
    }
  };

  const scrollRight = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({ left: 150, behavior: 'smooth' });
    }
  };

  return (
    <div className={`${categoryTabConfigurations.colorMode === 'transparent' ? "bg-[#26323829] " : 'bg-[#232F3E]'} `}>
      <div className='max-w-[1400px] mx-auto relative'>
        <div ref={containerRef} style={{ scrollbarWidth: 'none' }} className={` noscrollbar w-full flex gap-3 overflow-x-auto overflow-y-visible -z-10 transition-all  duration-500 ${categoryTabConfigurations.show ? ' md:top-[64px]  top-[113.6px] ' : 'transform -translate-y-full'}`}>

          {/* button left */}
          {showLeftArrow && (
            <button
              className="absolute left-0 top-1/2 transform -translate-y-1/2 z-10 p-1 bg-gradient-to-r from-[#232F3E] to-[#232f3e4c] text-white  "
              onClick={scrollLeft}
            >
              <IconChevronLeft />
            </button>
          )}
          {/* button right */}
          {showRightArrow && (
            <button
              className="absolute right-0 top-1/2 transform -translate-y-1/2 z-10 p-1 bg-gradient-to-r from-[#232f3e4c] to-[#232F3E]  text-white "
              onClick={scrollRight}
            >
              <IconChevronRight />

            </button>
          )}
          {isLoading ?
            Array(5).fill(5).map(
              item => (
                <div className="whitespace-nowrap rounded-full bg-gray-600 flex p-1 px-3 justify-center items-center h-[25px] animate-pulse">
                  <div className="bg-gray-500 rounded-full w-20 h-3"></div>
                </div>
              )
            )
            :
            <>
              <button onClick={() => navigate(`/all-products`)} className='py-2 whitespace-nowrap  hover:border-2 hover:bg-blue-gray-700 hover:m-1 m-1 border-2 border-transparent rounded-[2px]  text-white flex px-3 justify-center items-center font-semibold text-xs h-[25px]'>ALL</button>
              {categories.map((category, index) => {
                if (category.category_id != '2' && category.category_id != '123') {
                  return (
                    <CategoryDropDown key={index} category={category} />
                  )
                }
              })}
            </>
          }
        </div>
      </div>
    </div>
  );
}

export default CategoryTab;
