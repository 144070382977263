import { Button } from '@/src/components/ui/button'
import { Input } from '@/src/components/ui/input'
import { Label } from '@/src/components/ui/label'
import { useAlertModal } from '@/src/hooks/alert_modal'
import { useRESTapi } from '@/src/hooks/rest_API'
import { AdminModal, AdminModalContent, AdminModalFooter, AdminModalHeader, AdminModalMiddle, AdminModalTitle } from '@/src/pages/admin/components/modal'
import { IconLoader3 } from '@tabler/icons-react'
import { Loader2, Upload } from 'lucide-react'
import React, { useCallback, useRef, useState } from 'react'
import { useDropzone } from 'react-dropzone'

export function ImportModal({ show, setShow,refetch,rackId}: { show: boolean,rackId:number, setShow: (show: boolean) => any, refetch: () => void }) {

    const [file, setFile] = useState<File | null>(null);
    const dropzoneRef = useRef<any>(null);
    const alertModal = useAlertModal()
    const [isLoading,setIsLoading] = useState(false)
    const api = useRESTapi()


    const onDrop = useCallback((acceptedFiles: File[]) => {
        const uploadedFile = acceptedFiles[0];
        if (uploadedFile && uploadedFile.name.endsWith('.xlsx')) {
            setFile(uploadedFile);
        } else {
            alert('Please upload only .xlsx files');
        }
    }, []);


    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: {
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx']
        }
    });

    const handleUpload = async () => {
        setIsLoading(true)
        if (file) {
            const formData = new FormData();
            formData.append('file', file);
            const res = await api.post<{ success: boolean, message: string, errors: { row: number, message: string }[] }>({
                url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/bin/uploadBin/${rackId}`,
                body: formData,
                mode: 'admin',
            })

            if (res.data.success === true) {
                alertModal.open_alert_modal({
                    content: res.data.message,
                    footer: <Button onClick={alertModal.close_alert_modal}>Ok</Button>,
                    title: 'Stock added'
                })
                // Clear the file state and reset the dropzone
                setFile(null);
                if (dropzoneRef.current) {
                    dropzoneRef.current?.resetDropzone?.();
                }
                refetch()
                setShow(false)
            } else {
                alertModal.open_alert_modal({
                    content: res.data.message,
                    footer: <Button onClick={alertModal.close_alert_modal}>Ok</Button>,
                    title: 'Error'
                })
                setFile(null);
                if (dropzoneRef.current) {
                    dropzoneRef.current?.resetDropzone?.();
                }
            }
        } else {
            alert('Please select a file first');
        }
        setIsLoading(false)
    };

    return (
        <AdminModal show={show} setShow={(show) => {
            if (isLoading === false) {
                setShow(show)
            }
        }} >
            <AdminModalContent disableCloseButton={isLoading} >

                <>
                    <AdminModalHeader>
                        <AdminModalTitle>Import Bin</AdminModalTitle>
                    </AdminModalHeader>
                    <AdminModalMiddle>
                        <div className=' grow flex items-center justify-center'>
                            <div className="p-6 max-w-md mx-auto h-min bg-white rounded-xl shadow-md">
                                <h2 className="text-2xl font-bold mb-4">Excel Upload</h2>
                                <div
                                    {...getRootProps()}
                                    ref={dropzoneRef}
                                    className={`p-6 border-2 border-dashed rounded-lg text-center cursor-pointer ${isDragActive ? 'border-blue-500 bg-blue-50' : 'border-gray-300'
                                        }`}
                                >
                                    <input {...getInputProps()} />
                                    <Upload className="mx-auto h-12 w-12 text-gray-400" />
                                    <p className="mt-2 text-sm text-gray-600">
                                        {isDragActive
                                            ? 'Drop the Excel file here'
                                            : 'Drag and drop an Excel file here, or click to select'}
                                    </p>
                                </div>
                                {file && (
                                    <p className="mt-2 text-sm text-gray-600">
                                        Selected file: {file.name}
                                    </p>
                                )}
                                <button
                                    onClick={handleUpload}
                                    className="mt-4 w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex justify-center"
                                >
                                    {isLoading && <IconLoader3 className="mr-2 h-4 w-4 animate-spin" />}
                                    Upload Excel File
                                </button>
                            </div>
                        </div>
                    </AdminModalMiddle>
                    {/* <AdminModalFooter>
                        <Button variant={'outline'} disabled={isLoading} onClick={() => setShow(false)} >Cancel</Button>
                        <Button onClick={onDelete} disabled={isLoading} >{isLoading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}Uplaod</Button>
                    </AdminModalFooter> */}
                </>
            </AdminModalContent>
        </AdminModal>
    )
}
