import React, { useEffect, useState } from 'react';
import { useRESTapi } from '../../../../hooks/rest_API';
import { ProductCategory } from '../../../../types';
import { Link } from 'react-router-dom';

type CategoriesOverview = ProductCategory;

export default function Topcatagories() {
    const api = useRESTapi();
    const [top_selling_categories, set_top_selling_categories] = useState<CategoriesOverview[]>([]);
    const [is_loading, set_is_loading] = useState(false);

    const loadTopProducts = async () => {
        set_is_loading(true);
        try {
            const res = await api.get<{ success: boolean; data: CategoriesOverview[]; message: string }>({
                url: `${process.env.REACT_APP_BACKEND_URL_CUSTOMER!}/topsellingproduct_categories`,
                mode: 'customer',
                params: {
                    count: 8,
                },
            });

            if (res.data.success === true) {
                let newArray: CategoriesOverview[] = [];
                const limit = 5;
                let i = 0;
                res.data.data.forEach((item) => {
                    if (
                        item.M04_ProductCategoryId != '2' &&
                        item.M04_ProductCategoryId != '123' &&
                        item.M04_ParentCategoryId != '2' &&
                        item.M04_ParentCategoryId != '123'
                    ) {
                        if (i < limit) {
                            newArray.push({ ...item });
                            i++;
                        }
                    }
                });
                set_top_selling_categories(newArray);
            }
        } catch (error) {
            alert('Something went wrong');
        }
        set_is_loading(false);
    };

    useEffect(() => {
        loadTopProducts();
    }, []);

    return (
        <div className="relative flex flex-wrap justify-center gap-7 mt-8">
            {is_loading
                ? Array(5)
                      .fill(5)
                      .map((_, index) => (
                          <div
                              key={index}
                              className="h-28 w-28 flex flex-col items-center animate-pulse"
                          >
                              <div className="h-20 w-20 bg-gray-700 rounded-full"></div>
                              <div className="h-4 w-16 bg-gray-500 rounded mt-2"></div>
                          </div>
                      ))
                : top_selling_categories.map((brand, index) => (
                      <Link
                          to={`${brand.M04_ProductCategoryName?.replace(/ /g, '-').toLowerCase()}/categery-page?categoryId=${brand.M04_ProductCategoryId}`}
                          key={index}
                          className="flex flex-col items-center text-center min-w-[165px]"
                      >
                          {/* Rounded image */}
                          <div
                              className="h-24 w-full rounded-3xl rounded-b-none border-2 border-[#263238] overflow-hidden bg-gray-300"
                              style={{
                                  backgroundImage: `url(${process.env.REACT_APP_BACKEND_URL}/${brand.M04_ProductCategoryImage})`,
                                  backgroundPosition: 'center',
                                  backgroundSize: 'cover',
                                  backgroundRepeat:'no-repeat',
                                  backgroundColor:'white'
                              }}
                          ></div>
                          {/* Button-like category name */}
                          <h3 className=" min-w-[165px] px-3 py-1 bg-[#263238] text-white rounded-[100px] rounded-t-none text-[14px] font-medium hover:bg-gray-800 transition">
                              {brand.M04_ProductCategoryName}
                          </h3>
                      </Link>
                  ))}
        </div>
    );
}
